
  import Vue from "vue";
  import PwaIcon from '@/components/common/PwaIcon.vue';
  import PwaPrice from "@/components/common/PwaPrice.vue";
  import {ROUTES} from "@/router/routes";
  import {setLocale} from "@/services/i18n";
  import {MODAL_IDS} from "@/utils/modal";
  import {MINIGAMES_ALIAS} from "@/utils/minigames";

  export const MAX_MINIGAMES = 3;

  export default Vue.extend({
    name: "pwa-sidebar",
    components: {
      PwaIcon,
      PwaPrice,
    },
    data() {
      return {
        routes: ROUTES,
        MAX_MINIGAMES,
      }
    },
    methods: {
      changeLang(lang: string) {
        setLocale(lang, this.$router);
      },
      login() {
        this.showModalById(MODAL_IDS.LOGIN);
      },
      signUp() {
        this.showModalById(MODAL_IDS.SIGN_UP);
      },
      deposit() {
        if (this.isDesktop) {
          this.showModalById(MODAL_IDS.DEPOSIT);
        }else{
          this.$router.push({name: ROUTES.deposit.name, params: {lang: this.routeLang}});
        }
      }
    },
    computed: {
      getMainMinigameAlias(): string {
        let alias = MINIGAMES_ALIAS.FLAPPY_GOAL;

        const minigames = this.$store.state.user.minigames;
        if(minigames.length > 0){
          alias = minigames[0].alias;
        }

        return alias;
      },
      minigames: function () {
        return this.$store.state.user.minigames;
      },
      topMinigames: function () {
        let topMinigames = [];
        const minigames = this.$store.state.user.minigames;

        if (minigames.length > 0) {
          topMinigames = minigames.slice(0, MAX_MINIGAMES);
        }

        return topMinigames;
      },
      hasUnreadMessages(): boolean {
        return this.$store.getters.hasUnreadMessages;
      },
      hasBlog(): boolean {
        return Boolean(process.env.VUE_APP_BLOG_ENDPOINT);
      },
      hasInviteFriend(): boolean {
        return Boolean(process.env.VUE_APP_HAS_INVITE_FRIEND);
      },
      hasLastPrizesDistributed(): boolean {
        return Boolean(process.env.VUE_APP_HAS_LAST_PRIZES_DISTRIBUTED);
      },
      hasChallenges(): boolean {
        return Boolean(process.env.VUE_APP_HAS_CHALLENGES);
      },
      hasPromotions(): boolean {
        return Boolean(process.env.VUE_APP_HAS_PROMOTIONS);
      },
      xUrl(): string {
        return process.env.VUE_APP_X_URL;
      },
      instagramUrl(): string {
        return process.env.VUE_APP_INSTAGRAM_URL;
      },
      youtubeUrl(): string {
        return process.env.VUE_APP_YOUTUBE_URL;
      },
      whatsappUrl(): string {
        return process.env.VUE_APP_WHATSAPP_URL;
      },
    }
  });
